<template>
	<div class="wanted">
		<Topq></Topq>
		<div class="wanted-content">
			<div class="wanted-main">

				<div class="wanted-top">
					<div class="top-wanted">你想要找什么工作?</div>
					<div class="top-num"><span v-if="expectPost!=undefined">{{expectPost.length}}</span>/3</div>
				</div>
				<div class="wanted-banner">
					添加多个求职期望，可获得更多精准高薪工作机会
				</div>
				<div class="wanted-item">
					<div class="wanted-item-main" v-for="(item,index) in expectPost" :key="index">
						<div class="wanted-item-left">
							<div class="wanted-item-left-title">
								[ {{pcaaProvince(item.province)}} {{pcaaCity(item.province,item.city)}} ]
								{{item.postTitle}}
							</div>
							<div class="wanted-item-left-main">
								{{price(item.salary)}}元 {{item.category}}
							</div>
						</div>
						<div class="wanted-item-right">
							<div class="wanted-item-right-delete" @click="delect(item.postId)">
								删除岗位
							</div>
							<div class="wanted-item-right-change" @click="postSet(item.postId)">
								修改岗位
							</div>
						</div>

					</div>

				</div>
				<div @click="add" v-if="expectPost!=undefined">
					<div v-if="expectPost.length<3" class="wanted-item-add">
						<img src="../assets/image/add.png">
						<span>添加求职期望</span>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import {
		pcaa
	} from 'area-data';
	import $http from '../common/api/axios.js'
	import {
		expectPost,
		delExpectPost
	} from '../common/api/api.js'
	import Topq from '../components/common/Topq.vue'
	import Footer from './common/Footer.vue'
	export default {
		data() {
			return {
				options: [{
					value: '选项1',
					label: '离职-随时到岗'
				}, {
					value: '选项2',
					label: '在职-月内到岗'
				}, {
					value: '选项3',
					label: '在职-考虑机会'
				}, {
					value: '选项4',
					label: '在职-暂不考虑'
				}],
				value: '',
				expectPost: []
			}
		},
		components: {
			Topq,
			Footer

		},
		methods: {
			pcaaProvince(province) {
				let num1 = 86
				let num2 = province
				return pcaa[num1][num2]
			},
			pcaaCity(province, city) {
				let num1 = province
				let num2 = city
				return pcaa[num1][num2]

			},
			price(salaryType) {
				let xinoptions = this.$store.state.xinoptions;
				return xinoptions[salaryType].label
			},
			postSet(postId) {
				let id = postId
				this.$router.push({
					path: '/pj-postSet',
					query: {
						id: id
					}
				})
			},
			add() {
				this.$router.push('./pj-postSet')
			},
			delect(postId) {
				let id = postId
				$http.request({
					url: delExpectPost,
					data: {
						"postId": id
					}
				}).then((res) => {
					if (res.code == 200) {
						this.$router.go(0)
					}
				}).catch(() => {

				});
			}
		},
		created() {
			this.$store.dispatch('initDic').then(() => {
				let that = this
				$http.request({
					url: expectPost,
				}).then((res) => {
					that.expectPost = res.data.expectPostLists
				}).catch(() => {

				});

			})
		}
	}
</script>

<style scoped>
	.wanted-item-add {
		margin-left: 3rem;
		margin-bottom: .96rem;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 5.67rem;
		height: .81rem;
		background: #E41D1D;
		border-radius: 6px;
	}

	.wanted-item-add img {
		margin-right: .1rem;
		width: .24rem;
	}

	.wanted-item-add span {
		font-family: PingFang SC;
		font-size: .18rem;
		font-weight: 400;
		color: #FFFFFF;
	}

	.wanted-item-right {
		padding-top: .1rem;
		width: 4rem;
		height: 100%;
		display: flex;
	}

	.wanted-item-right-delete {
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 1.2rem;
		height: .48rem;
		background: #FFFFFF;
		border: 2px solid #666666;
		border-radius: 6px;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
		cursor: pointer;
	}

	.wanted-item-right-change {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: .16rem;
		width: 1.2rem;
		height: .48rem;
		background: #666666;
		border-radius: 6px;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		cursor: pointer;
	}

	.wanted-item-left {
		width: 10rem;
	}

	.wanted-item-left-title {
		margin-bottom: .12rem;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #333333;
		line-height: .30rem
	}

	.wanted-item-left-main {
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: .30rem
	}

	.wanted-item {
		min-height: 2rem;
		margin-bottom: .2rem;
	}

	.wanted-item-main {
		display: flex;
		box-sizing: border-box;
		padding-left: .4rem;
		padding-top: .48rem;
		margin-bottom: .2rem;
		width: 11rem;
		height: 1.6rem;
		border-top: 2px solid #E9E9E9;
	}

	.wanted-state {
		display: flex;
		margin-bottom: .4rem;
	}

	.wanted-state span {
		font-size: .18rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #333333;
		line-height: .54rem;
	}

	.wanted-top {
		box-sizing: border-box;
		padding-right: .5rem;
		padding-top: .7rem;
		display: flex;
		justify-content: space-between;
	}

	.top-wanted {
		font-size: .24rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #333333;
		line-height: 30px;
	}

	.top-num {
		font-size: .24rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #333333;
		line-height: 30px;
	}

	.top-num span {
		color: #762FEF;
	}

	.wanted-banner {
		margin-bottom: .5rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 30px;
	}

	.wanted {
		display: flex;
		flex-direction: column;
		width: 19.2rem;
	}

	.wanted-content {
		padding-left: 3.6rem !important;
		padding-right: 3.6rem !important;
		width: 100%;
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	.wanted-main {
		box-sizing: border-box;
		padding-left: .4rem;
		width: 12rem;
		border: 1px solid rgba(172, 172, 172, 0.2);
	}
</style>
